import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CommonToastService } from '@common2/services/toast.service';
import { CompanyUserProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { take } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class WatchSettingsComponent implements OnInit {
  @ViewChild('modalAdd') modalAdd: CommonModalComponent;
  @ViewChild('modalAddOld') modalAddOld: CommonModalComponent;
  public dashboardItemName: string = '';
  public dashboardItemDescription: string = '';
  public dashboardItemUrl: string = '';
  public dashboardItemNameOld: string = '';
  public dashboardItemDescriptionOld: string = '';
  public dashboardItemUrlOld: string = '';
  public modalTitle: string = '';
  public tableColumnns: any = ['name', 'description', 'status', 'edit', 'delete'];
  public tableContent: any;
  public isLoading: boolean = false;
  public currentDashboardId: string = '';
  public isEdit: boolean = false;
  public isPowerBienabled: boolean = false;
  public workspaces: any = [];
  public workspacesReports: any = [];
  public selectedWorkspace: any = {};
  public selectedWorkspaceReport: any = {};
  public workspacesReportsExist: boolean = false;
  public editItem: any = {};
  constructor(
    private companyUserProvider: CompanyUserProvider,
    private storageService: StorageService,
    private toastService: CommonToastService,
    private translateService: TranslateService
  ) { 

  }

  ngOnInit(): void {
    this.getDashboards();
    this.checkPowerBi();
    this.getworkspaces();
  }

  public openModalAdd(): void {
    this.resetFields();
    this.modalTitle = 'create';
    this.isEdit = false;
    this.modalAdd.onOpen();
  }


  public createDashboard(): void {
    const item = {
      name:  this.dashboardItemName,
      description: this.dashboardItemDescription,
      reportId: this.selectedWorkspaceReport.reportId,
      url: this.selectedWorkspaceReport.url,
      workspaceId: this.selectedWorkspaceReport.workspaceId,
    }
    this.companyUserProvider.CreateDashboard(this.storageService.get('currentExperience').id, item).pipe(take(1)).subscribe(data => {
      this.toastService.onSuccess(this.translateService.instant('dashboard.created'));
      this.modalAdd.onClose();
      this.getDashboards();
    }, (error) => {
      this.toastService.onError(error.error);
    })
  }

  public getDashboards(): void {
    this.companyUserProvider.GetDashboard(this.storageService.get('currentExperience').id).pipe(take(1)).subscribe(data => {
      this.tableContent = data.dashboards;
    })
  }

  public deleteDashboard(dashboardId: any): void {
    this.companyUserProvider.DeleteDashboard(this.storageService.get('currentExperience').id, dashboardId).pipe(take(1)).subscribe(data => {
      this.toastService.onSuccess(this.translateService.instant('dashboard.deleted'));
      this.getDashboards();
    })
  }

  public changeStatus(dashboardId: any): void {
    this.companyUserProvider.StatusUpdate(this.storageService.get('currentExperience').id, dashboardId).pipe(take(1)).subscribe(data => {
      this.toastService.onSuccess(this.translateService.instant('dashboard.status-updated'));
    })
  }

  public updateDashboard(): void {
    const item = {
      name:  this.dashboardItemName,
      description: this.dashboardItemDescription,
      reportId: this.selectedWorkspaceReport.reportId,
      url: this.selectedWorkspaceReport.url,
      workspaceId: this.selectedWorkspaceReport.workspaceId,
    }
    this.companyUserProvider.UpdateDashboard(this.storageService.get('currentExperience').id, this.currentDashboardId, item).pipe(take(1)).subscribe(data => {
      this.toastService.onSuccess(this.translateService.instant('dashboard.updated'));
      this.modalAdd.onClose();
      this.getDashboards();
    })

  }

  public resetFields(): void {
    this.dashboardItemName = '';
    this.dashboardItemDescription =  '';
    this.dashboardItemUrl = '';
    this.selectedWorkspace = {};
    this.selectedWorkspaceReport = {};
    this.workspacesReportsExist = false;
  }

  public openEdit(data: any):void {
    this.editItem = data;
    this.resetFields();
    this.getworkspaces();
    this.selectedWorkspace = data;
    const workspace = this.workspaces.find((item: any) => item.id === data.workspaceId);
    this.onSelectWorkspace(workspace, true);
    this.isEdit = true;
    this.modalTitle = 'update';
    this.dashboardItemName = data.name;
    this.dashboardItemDescription = data.description;
    this.currentDashboardId = data.uuid;
    this.modalAdd.onOpen();
  }

  public openModalAddOld(): void {
    this.resetFields();
    this.modalTitle = 'create';
    this.isEdit = false;
    this.modalAddOld.onOpen();
  }
  

  public createDashboardOld(): void {
    const item = {
      name: this.dashboardItemNameOld,
      description: this.dashboardItemDescriptionOld,
      url: this.dashboardItemUrlOld
    }
    this.companyUserProvider.CreateDashboard(this.storageService.get('currentExperience').id, item).pipe(take(1)).subscribe(data => {
      this.toastService.onSuccess(this.translateService.instant('dashboard.created'));
      this.modalAddOld.onClose();
      this.getDashboards();
    }, (error) => {
      this.toastService.onError(error.error);
    })
  }

  public updateDashboardOld(): void {
    const item = {
      name: this.dashboardItemNameOld,
      description: this.dashboardItemDescriptionOld,
      url: this.dashboardItemUrlOld
    }
    this.companyUserProvider.UpdateDashboard(this.storageService.get('currentExperience').id, this.currentDashboardId, item).pipe(take(1)).subscribe(data => {
      this.toastService.onSuccess(this.translateService.instant('dashboard.updated'));
      this.modalAddOld.onClose();
      this.getDashboards();
    })
  }
  
  public resetFieldsOld(): void {
    this.dashboardItemNameOld = '';
    this.dashboardItemDescriptionOld =  '';
    this.dashboardItemUrlOld = '';
  }

  public openEditOld(data: any):void {
    this.resetFieldsOld();
    this.isEdit = true;
    this.modalTitle = 'update';
    this.currentDashboardId = data.uuid;
    this.dashboardItemNameOld = data.name;
    this.dashboardItemDescriptionOld = data.description;
    this.dashboardItemUrlOld = data.url;
    this.modalAddOld.onOpen();
  }

  public checkPowerBi(): any {
    this.companyUserProvider.checkPowerBi().pipe(take(1)).subscribe(data => {
      this.isPowerBienabled = data.newPowerBi;
    })
  }

  public getworkspaces(): void {
    this.companyUserProvider.getWorkspaces().pipe(take(1)).subscribe(data => {
      this.workspaces = data.workspaces;
    })
  }

  public onSelectWorkspace(workspace: any, isEdit?: boolean): void {
    this.isLoading = true;
    this.workspacesReportsExist = false;
    this.selectedWorkspace = workspace;
    this.selectedWorkspaceReport = {};
    const params = {
      workspaceId: workspace.id
    }
    this.companyUserProvider.getReportsWorkspaces(params).pipe(take(1)).subscribe(data => {
      this.workspacesReports = data.workspaceReports;
      this.workspacesReportsExist = true;
      this.isLoading = false;
      if(isEdit) {
        const dataReport = this.workspacesReports.find((item: any) => item.reportId === this.editItem.reportId);
        this.onSelectWorkspaceReport(dataReport);
      }
    })
  }

  public onSelectWorkspaceReport(workspaceReport: any): void {
    this.selectedWorkspaceReport = workspaceReport;
  }
}
