import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CompanyUserProvider } from '@lighty';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { StorageService } from '@services';
import { take } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild('iframe') iframe!: ElementRef;

  public selectList: any;
  public isLoading: boolean = true;
  public urlSafe: SafeResourceUrl;
  public currentSelection: string = ''; 
  public powerbi: any;
  public isPowerBienabled: boolean = false;

  constructor(
    private companyUserProvider: CompanyUserProvider,
    private storageService: StorageService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.companyUserProvider.GetDashboardOnlyActive(this.storageService.get('company').experience.id)
      .pipe(take(1))
      .subscribe((data) => {
        this.selectList = data.dashboards;
        this.currentSelection = data.dashboards[0].name;
        this.loadDashboard(data.dashboards[0].uuid);
      });
      this.checkPowerBi();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
        this.powerbi = (window as any).powerbi;
    }, 1000);
}

  public loadDashboard(dashboardId: any, dashboardName?: string): void {
    if(dashboardName)
      this.currentSelection = dashboardName;
    this.companyUserProvider.ShowDashboard(this.storageService.get('company').experience.id, dashboardId)
      .pipe(take(1))
      .subscribe((data) => {
        console.log(data);
        if(this.isPowerBienabled) {
          this.embedDashboard(data);
        } else {
            const newUrl = data.url;
            this.updateIframeUrl(newUrl);
            this.isLoading = false;
        }
      });
  }

  public updateIframeUrl(newUrl: string): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(newUrl);
  }

  changeUrl() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://griky.1mentor.io/auth/login'
    );
  }

  public embedDashboard(dashboardInfo?: any): void {
      const embedContainer = document.getElementById('powerbi-container');
      const embedConfig = {
          type: 'report',
          id: dashboardInfo.id,
          embedUrl: dashboardInfo.url,
          accessToken:  dashboardInfo.token,
          tokenType: 1,
          settings: {
              filterPaneEnabled: true,
              navContentPaneEnabled: true,
          },
      };
      this.powerbi.embed(embedContainer, embedConfig)
  }

  public checkPowerBi(): any {
    this.companyUserProvider.checkPowerBi().pipe(take(1)).subscribe(data => {
      this.isPowerBienabled = data.newPowerBi;
      this.isLoading = false;
    })
  }
}
