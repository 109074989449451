import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, IntegrationProvider, Pagination } from '@lighty';
import { DataHelper, UrlHelper } from '@helpers';
import { ExternalAppsService, StorageService, TranslateService } from '@services';
import { CommonToastService } from '@common2/services/toast.service';

export const DEFAULT_PROVIDER_LIST_KEYS: ReadonlyArray<string> = [
    'appcues',
    'bookboon',
    'cefora',
    'cegos',
    'coursera',
    'edflex-blue',
    'email-apside',
    'freshchat',
    'goodhabitz',
    'gotowebinar',
    'mymooc',
    'openclassroom',
    'trainingexpress',
    'udemy',
    'youtube',
    'linkedin',
    'aws',
    'learninghubz',
    'coursera-for-campus',
    'boclips',
    'netzun',
    'onementor',
    'coursera-career-academy',
    'xertify',
    'power_bi_dashboards',
    'platzi',
    'coursera-multi-programs'
];

@Component({
    selector: 'msc-organize-integration-providers',
    templateUrl: './providers.component.html',
    styleUrls: ['./providers.component.scss'],
})

export class OrganizeIntegrationsProvidersComponent {
    private company: Company;
    public me: any;
    public providers: any[];
    public pagination: Pagination;
    public availableProviders: any[];
    public displayAsides: any;
    public integrationCollection: string[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private integrationProvider: IntegrationProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
        private externalAppsService: ExternalAppsService,
        private readonly storageService: StorageService,
    ) { }

    ngOnInit(): void {
        this.company = this.route.snapshot.parent.parent.parent.data.company;
        this.providers = (this.route.snapshot.parent.data.providers.providers || []).map((provider: any) => {
            const isPredefined = DEFAULT_PROVIDER_LIST_KEYS.includes(provider.provider.key);
            const mediaUrl = provider.provider.media ? `url(${provider.provider.media.pictureUrl}?size=256)` : null;
            return { ...provider, isPredefined, mediaUrl };
        });
        this.pagination = this.route.snapshot.parent.data.providers.pagination;
        this.availableProviders = this.sortProviders(this.route.snapshot.parent.data.availableProviders.providers);
        this.availableProviders = this.getAvailableProvidersRemapped(this.availableProviders);

        this.integrationCollection = [
            'change-the-work.png',
            'cll.png',
            'diduenjoy.png',
            'dynalearn.png',
            'easy-driver.png',
            'eni-logo.svg',
            'evolitude.png',
            'genially.png',
            'global-exam.png',
            'iam-learning.png',
            'ingredient-films.png',
            'japan-consulting.png',
            'jean-pierre-villatte.png',
            'kokoroe.png',
            'linkedin-learning.png',
            'linkedin.png',
            'pluralsight.png',
            'powtoon.png',
            'quality-training.png',
            'somanyways.png',
            'upskill-logo.png',
            'xos.png',
            'yodise.png',
        ];
        this.me = this.storageService.get('me'); 
        this.filterAndRemoveProvider();
    }

    contact(): void {
        UrlHelper.mailTo('sales@myskillcamp.com');
    }

    redirectProvider(provider: any): void {
        const url = `organize/${this.company.slug}/integrations/providers/${provider.id}/settings`;
        this.router.navigateByUrl(url);
    }

    redirectAvailableProvider(provider: any): void {
        if (provider.permission) {
            const permission = DataHelper.changeCase(provider.permission, 'camel');
            if (!this.company.permissions[permission]) {
                this.contactSalesTeam(provider);
            } else {
                const params = {
                    status: 1
                };
                this.integrationProvider.updateStatus(this.company.id, provider.id, params).subscribe(() => {
                    const url = `organize/${this.company.slug}/integrations/providers/${provider.id}/settings`;
                    this.router.navigateByUrl(url);
                }, (err) => {
                    this.toastService.onError(this.translateService.instant(err.error.error));
                });
            }
        } else {
            if (provider.key === 'openclassroom') {
                const params = {
                    status: 1
                };
                this.integrationProvider.updateStatus(this.company.id, provider.id, params).subscribe(() => {
                    const url = `organize/${this.company.slug}/integrations/providers/${provider.id}/settings`;
                    this.router.navigateByUrl(url);
                });
            } else {
                const url = `organize/${this.company.slug}/integrations/providers/${provider.id}/settings`;
                this.router.navigateByUrl(url);
            }
        }
    }

    contactSalesTeam(provider: any): void {
        const params = {
            companyId: this.company.id
        };

        this.integrationProvider.contactAdministrator(provider.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.theme.request.mail'));
        });
    }

    checkButtonWord(provider: any): string {
        if (provider.permission) {
            const permission = DataHelper.changeCase(provider.permission, 'camel');
            if (this.company.permissions[permission]) {
                return 'words.connect';
            } else {
                return 'words.contact-us';
            }
        }

        if (provider.key === 'udemy') {
            return 'words.connect';
        }
        return 'words.connect';
    }

    sortProviders(providers: any): any {
        return providers.map((provider) => {
            return { ...provider, sorting: this.sortingProvider(provider) };
        }).sort((a, b) => {
            if (a.sorting > b.sorting) {
                return 1;
            }

            if (a.sorting < b.sorting) {
                return -1;
            }

            return 0;
        });
    }

    getAvailableProvidersRemapped(data: Array<any>) {
        return data.map((datum: any) => {
            const isPredefined = DEFAULT_PROVIDER_LIST_KEYS.includes(datum.key);
            const mediaUrl = datum.media ? `url(${datum.media.pictureUrl}?size=256)` : null;
            return { ...datum, isPredefined, mediaUrl };
        });
    }

    sortingProvider(provider: any): number {
        if (provider.permission) {
            const permission = DataHelper.changeCase(provider.permission, 'camel');
            if (this.company.permissions[permission]) {
                return 1;
            } else {
                return 2;
            }
        }

        if (provider.key === 'udemy') {
            return 1;
        }
        return 1;
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    getResource(resource: string): string {
        return 'https://static.myskillcamp.com/images/partners/' + resource;
    }
    filterAndRemoveProvider(): void {
        const index = this.availableProviders.findIndex(provider => 
            provider.key === 'power_bi_dashboards' && !this.me.email.includes('@griky.co')
          );
          console.log(index);
          if (index !== -1) {
            this.availableProviders.splice(index, 1);
          }
    }
}
