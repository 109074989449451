import { Component } from '@angular/core';
import { Company, CompanyUserProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { take } from 'rxjs';

@Component({
    selector: 'msc-organize-statistic',
    templateUrl: './statistic.component.html',
    styleUrls: ['./statistic.component.scss']
})

export class OrganizeStatisticComponent {
    public navData: Array<any>;
    public company: Company;

    constructor(
        private readonly storageService: StorageService,
        private readonly translateService: TranslateService,
        private companyUserProvider: CompanyUserProvider
    ) { }

    ngOnInit(): void {


        this.company = this.storageService.get('company');
        this.companyUserProvider.GetDashboardOnlyActive(this.storageService.get('company').experience.id)
        .pipe(take(1))
        .subscribe((data) => {
            this.navData = this.getNav();
            if(data.dashboards.length == 0){
                this.navData.splice(1,1);
            }
        });
    }

    /**
     * Get the nav data of the page
     */
    getNav(): Array<any> {
        return [
            {
                label: this.translateService.instant(`words.adoption`),
                value: {
                    routerLink: 'adoption'
                }
            },
            {
                label: this.translateService.instant(`dashboard.menu`),
                value: {
                    routerLink: 'dashboard',
                    isNew: false,
                }
            },
            {
                label: this.translateService.instant(`exports.title`),
                value: {
                    routerLink: 'exports',
                    isNew: false,
                }
            },
        ]
    }
}
