
<div class="content-experience content-experience--catalogue">
    <div class="content-experience__body">
        <div class="box box-experience">
            <div class="box-experience__title mb-3">
                <h3>{{ 'words.dashboard-settings' | translate }}</h3>
            </div>
            <msc-button class="msc-button--primary" icon="icon-plus" palette="secondary" (click)="openModalAdd()" *ngIf="isPowerBienabled">
                {{ 'words.add' | translate }}
            </msc-button>

            <msc-button class="msc-button--primary" icon="icon-plus" palette="secondary" (click)="openModalAddOld()" *ngIf="!isPowerBienabled">
                {{ 'words.add' | translate }}
            </msc-button>

            <div class="flex flex-col gap-4 mt-10">
                <div class="flex flex-row box-experience__title">
                    <div class="w-1/3">
                        <span>{{'common.name' | translate}}</span>
                    </div>
                    <div class="w-1/3">
                        <span>{{'common.description' | translate}}</span>
                    </div>
                    <div class="w-1/12">
                        <span>{{'common.status' | translate}}</span>
                    </div>
                    <div class="w-1/12 mr-6">
                        <span>{{'common.edit' | translate}}</span>
                    </div>
                    <div class="w-1/12">
                        <span>{{'common.delete' | translate}}</span>
                    </div>
                </div>

                <div class="flex flex-col gap-4 mt-6">
                    <div class="flex flex-row w-full box-experience__title" *ngFor="let row of tableContent">
                        <div class="w-1/3">
                            <span>{{row.name}}</span>
                        </div>
                        <div class="w-1/3">
                            <span>{{row.description}}</span>
                        </div>
                        <div class="w-1/12">
                            <msc-common2-switch [value]="row.status" (changeEvent)="changeStatus(row.uuid)"></msc-common2-switch>
                        </div>
                        <div class="w-1/12 mr-6">
                            <msc-button class="msc-button--primary" icon="icon-edit-2" palette="secondary" [tooltip]="'common.edit' | translate"
                            (click)="openEdit(row)" *ngIf="isPowerBienabled">
                                {{'common.edit' | translate}}
                            </msc-button>
                            <msc-button class="msc-button--primary" icon="icon-edit-2" palette="secondary" [tooltip]="'common.edit' | translate"
                            (click)="openEditOld(row)" *ngIf="!isPowerBienabled">
                                {{'common.edit' | translate}}
                            </msc-button>
                        </div>
                        <div class="w-1/12">
                            <msc-button class="msc-button--primary" icon="icon-trash-2" palette="secondary" [tooltip]="'common.delete' | translate"
                            (click)="deleteDashboard(row.uuid)">
                                {{'common.delete' | translate}}
                            </msc-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<msc-common-modal size="30vw" #modalAdd [title]="'words.dashboard-settings-' + modalTitle | translate">
    <ng-template>
        <msc-input [label]="'words.name' | translate"
        [placeholder]="'words.name' | translate"
        [(ngModel)]="dashboardItemName"
        ></msc-input>
        <msc-input [label]="'words.description' | translate"
        [placeholder]="'words.description' | translate"
        [(ngModel)]="dashboardItemDescription"
        ></msc-input>

        <div class="flex flex-col justify-start w-full">
            {{'words.workspace' | translate}}
            <msc-common-select class="text-sm w-full" [data]="workspaces">
                <ng-template #buttonTemplate let-data="data">
                    <button class="select-main">
                        <ng-container>
                            <span>{{selectedWorkspace.name}}</span>
                        </ng-container>
                    </button>
                </ng-template>
                <ng-template #optionTemplate let-datum="datum">
                    <button class="select-option" (click)="onSelectWorkspace(datum)">
                        <span>{{ datum.name }}</span>
                    </button>
                </ng-template>
            </msc-common-select>
        </div>

        <msc-common-spinner *ngIf="isLoading"></msc-common-spinner>

        <div class="flex flex-col justify-start w-full" *ngIf="workspacesReportsExist">
            {{'words.report' | translate}}
            <msc-common-select class="text-sm w-full" [data]="workspacesReports">
                <ng-template #buttonTemplate let-data="data">
                    <button class="select-main">
                        <ng-container>
                            <span>{{selectedWorkspaceReport.name}}</span>
                        </ng-container>
                    </button>
                </ng-template>
                <ng-template #optionTemplate let-datum="datum">
                    <button class="select-option" (click)="onSelectWorkspaceReport(datum)">
                        <span>{{ datum.name }}</span>
                    </button>
                </ng-template>
            </msc-common-select>
        </div>

        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button palette="primary" (click)="modalAdd.onClose()">
                {{'common.cancel' | translate}}
            </msc-button>

            <msc-button *ngIf="!isEdit" class="msc-button--primary" palette="primary" (click)="createDashboard()">
                {{'common.save' | translate}}
            </msc-button>

            <msc-button *ngIf="isEdit" class="msc-button--primary" palette="primary" (click)="updateDashboard()">
                {{'common.edit' | translate}}
            </msc-button>
        </div>
    </ng-template>
</msc-common-modal>

<msc-common-modal size="30vw" #modalAddOld [title]="'words.dashboard-settings-' + modalTitle | translate">
    <ng-template>
        <msc-input [label]="'words.name' | translate"
        [placeholder]="'words.name' | translate"
        [(ngModel)]="dashboardItemNameOld"
        ></msc-input>
        <msc-input [label]="'words.description' | translate"
        [placeholder]="'words.description' | translate"
        [(ngModel)]="dashboardItemDescriptionOld"
        ></msc-input>
        <msc-input [label]="'words.url' | translate"
        [placeholder]="'words.url' | translate"
        [(ngModel)]="dashboardItemUrlOld"
        ></msc-input>

        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button palette="primary" (click)="modalAddOld.onClose()">
                {{'common.cancel' | translate}}
            </msc-button>

            <msc-button *ngIf="!isEdit" class="msc-button--primary" palette="primary" (click)="createDashboardOld()">
                {{'common.save' | translate}}
            </msc-button>

            <msc-button *ngIf="isEdit" class="msc-button--primary" palette="primary" (click)="updateDashboardOld()">
                {{'common.edit' | translate}}
            </msc-button>
        </div>
    </ng-template>
</msc-common-modal>
